<template>
  <div class="firstslide:hidden flex space-x-4 sm:space-x-5">
    <!-- PREV BUTTON -->
    <button
      :tabindex="isBeginning ? '-1' : tabindex"
      class="flex h-12 w-12 cursor-default items-center justify-center rounded-full border-2 border-blue-500 opacity-50 outline-none ring-blue-500 ring-offset-2 transition-colors hover:border-blue-600 focus-visible:ring-2 green:border-green-500 green:ring-green-500 hover:green:border-green-600 pink:border-pink-500 pink:ring-pink-500 hover:pink:border-pink-600 sm:h-16 sm:w-16"
      :class="{
        'bg-blue-500 group pink:bg-pink-500 green:bg-green-500 border-none  hover:bg-blue-600 green:hover:bg-green-600 pink:hover:bg-pink-600 !cursor-pointer opacity-100':
          !isBeginning,
      }"
      aria-label="Verschiebe Slider nach links"
      @click="$emit('prev')"
    >
      <IconArrow
        class="h-3 w-3 rotate-180 transition group-hover:-translate-x-1 sm:h-4 sm:w-4"
        :class="
          !isBeginning
            ? 'text-white'
            : 'text-blue-500 green:text-green-500 pink:text-pink-500 '
        "
      />
    </button>

    <!-- NEX BUTTON -->
    <button
      :tabindex="isEnd ? '-1' : tabindex"
      class="flex h-12 w-12 cursor-default items-center justify-center rounded-full border-2 border-blue-500 opacity-50 outline-none ring-blue-500 ring-offset-2 transition-colors focus-visible:ring-2 green:border-green-500 green:ring-green-500 pink:border-pink-500 pink:ring-pink-500 sm:h-16 sm:w-16"
      :class="{
        'bg-blue-500 group pink:bg-pink-500 green:bg-green-500 hover:bg-blue-600 border-none green:hover:bg-green-600 pink:hover:bg-pink-600 !cursor-pointer opacity-100':
          !isEnd,
      }"
      aria-label="Verschiebe Slider nach rechts"
      @click="$emit('next')"
    >
      <IconArrow
        class="h-3 w-3 transition group-hover:translate-x-1 sm:h-4 sm:w-4"
        :class="
          !isEnd
            ? 'text-white'
            : 'text-blue-500 green:text-green-500 pink:text-pink-500 '
        "
      />
    </button>
  </div>
</template>

<script>
// use this component ether with ref or with props.
// usually use ref on the functions here. It is be less to configure on the parent (Gallery.vue)
// if you render this button multiple times e.g. on each slide use props ref will not work. (TeaserLargeSlider.vue)
// in this case you need to define the functions on the parent and pass them as props to this component

export default {
  props: {
    reachBeginning: {
      type: Boolean,
      required: false,
    },

    reachEnd: {
      type: Boolean,
      required: false,
    },

    tabindex: {
      type: String,
      default: '0',
    },
  },

  data() {
    return {
      isBeginning: true,
      isEnd: false,
    }
  },

  watch: {
    reachBeginning(newVal) {
      this.isBeginning = newVal
    },

    reachEnd(newVal) {
      this.isEnd = newVal
    },
  },

  methods: {
    async reachedBeginningOrEnd(beginningOrEnd) {
      // need to wait until fromEdge event has set its values
      await nextTick()

      if (beginningOrEnd === 'beginning') {
        this.isBeginning = true
      } else {
        this.isEnd = true
      }
    },

    fromEdge() {
      this.isBeginning = false
      this.isEnd = false
    },
  },
}
</script>

<style lang="scss">
.swiper-wrapper .swiper-slide:only-child {
  .firstslide\:hidden {
    @apply hidden;
  }
}
</style>
